import MDRouter from "@/service/router";
// import lowerdev from "@/service/lowerdev"
import redirect from "@/service/redirect";
import { getSchemeUrl } from "@/utils/toolsApi";
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import { get, post } from '@/utils/apiBase.js';
import { env } from "md-base-tools/env";
import domain from "@/service/domain";
import CompProphet from "@@/project/prophet.vue";
import { mdToast } from "../../utils/tools";
export default {
  name: "lottery",
  head() {
    return {
      title: "大预言家"
    };
  },
  watch: {},
  components: {
    CompProphet
  },
  data() {
    return {
      env,
      startMoneyStr: 0,
      endMoneyStr: 0,
      getBetIng: false,
      everyTimeIndex: 1,
      //每次下注金额
      out_listIdx: 0,
      time: 0,
      day: 0,
      hour: 0,
      minute: 0,
      sec: 0,
      activity_id: this.$route.params.id,
      status: 4,
      echartsPagesize: 100,
      statusType: 3,
      statusTypeMap: {
        1: "我预言众筹“最终金额”",
        2: "我预言众筹“最终金额”",
        3: "中奖名单",
        10: "我预言众筹“最终金额”"
      },
      receive_info_Map: {
        0: '领取',
        1: '已领取',
        2: '去支持'
      },
      isIphoneX: "",
      user_id: this.$store.state.user.userId,
      detailData: null,
      greenNum: 0,
      my_quiz_info: [],
      my_quiz_info_index: 0,
      echartsLow: 0,
      echartsHeight: 0,
      out_list: [],
      lotter: 1,
      start_money: null,
      end_money: null,
      noIntegral: false,
      allShow: false,
      timeOut: null,
      timeOutList: null,
      timeOutListIdx: 0,
      localTop: 0,
      company: 0,
      statusBarH: 0,
      total: 0,
      bet_info_company: "",
      recordList: [],
      dataUser: [],
      page: 1,
      allList: [],
      winbg: false
    };
  },
  beforeMount() {},
  mounted() {
    window.userHeader.titleText('预言家');
    // eslint-disable-next-line no-undef
    userHeader.getMdlink({
      type: "link",
      web_url: window.location.href,
      lottery: true
    });
    // eslint-disable-next-line no-undef
    graft.app({
      functionName: "onGetDeviceInfo",
      functionParams: {},
      callBack: e => {
        this.statusBarH = e.data.statusBarH;
      }
    });
    if (!this.user_id && (env.isModianIos() || env.isModianAndroid())) {
      this.getDetail();
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "onGetUserInfo",
        functionParams: {},
        callBack: e => {
          if (e.errorCode == 0) {
            this.$store.commit({
              type: "setGlobalEnv",
              obj: "loginState",
              value: {
                user_id: e.data.user_id ? e.data.user_id : e.data.userId,
                token: e.data.token,
                icon: this.$cookies.get("MDUSERINFO") || ""
              }
            });
            // setTimeout(() => {
            //     this.getDetail()
            // })
          } else {
            // eslint-disable-next-line no-undef
            // graft.app({
            //     functionName: "login",
            //     functionParams: {},
            // })
          }
        },
        webFun: () => {
          // redirect.to("login")
        }
      });
    } else {
      this.getDetail();
    }
  },
  beforeUnmount() {
    if (this.lotter == 2) {
      this.quizBet();
    }
  },
  methods: {
    login() {
      if (env.isWxApp()) {
        let wxhost = "/pages/mainPages/login/login?";
        let address = encodeURIComponent(location.href);
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: wxhost + "&redirect_url=" + address
        });
        return;
      } else {
        // eslint-disable-next-line no-undef
        graft.app({
          functionName: "login",
          functionParams: {},
          callBack: () => {
            location.reload();
          },
          webFun: () => {
            redirect.to("login");
          }
        });
      }
    },
    touserscore() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: 'md://user_score',
        callBack: () => {
          location.reload();
        },
        webFun: () => {}
      });
    },
    timeFilter(str) {
      if (str) {
        let a = str.split(" ")[0].split("-");
        let b = str.split(" ")[1].split(":");
        return `${a[1]}月${a[2]}日 ${b[0]}:${b[1]} `;
      }
    },
    moneyFilter(num) {
      //判断是否是数字
      if (num == 0) return 0;
      if (!num) return "";
      num = Number(num);
      return num.toLocaleString("en-US");
    },
    async noApp() {
      const url = await this.getScheme();
      if (url) {
        location.href = url;
      }
    },
    gotoRong() {
      if (sessionStorage.getItem("miniProgram") == "weixin") {
        let wxhost = "/pages/userPages/user/user?user_id=" + this.user_id;
        let address = encodeURIComponent(location.href);
        if (this.detailData.prize_list[0].self) {
          // eslint-disable-next-line no-undef
          wx.miniProgram.navigateTo({
            url: wxhost + "&redirect_url=" + address
          });
        } else {
          mdToast("请前往「我的」联系官方客服领取奖品");
        }
      } else {
        let zcUrl = 'md://ucenter?id=' + this.user_id;
        if (this.detailData.prize_list[0].self) {
          zcUrl = `https://kefu.sobot.com/chat/h5/v2/index.html?sysnum=95f4d32b23be449e9c0b9889667bda4a&uname=&tel=&partnerid=${this.user_id}&channelid=2`;
        }
        // eslint-disable-next-line no-undef
        graft.app({
          functionName: "openController",
          functionParams: zcUrl
        });
      }
    },
    taskBt1() {
      if (this.statusType !== 1) {
        return;
      }
      if (this.detailData.receive_info.status === 2) {
        this.goToProject(this.detailData.pro_info.id);
      } else if (this.detailData.receive_info.status === 0) {
        post("/apis/activities/prophet/receive", {
          activity_id: this.activity_id
        }).then(({
          message,
          status
        }) => {
          if (status) {
            return mdToast(message);
          }
          mdToast(`领取成功，预言次数+${this.detailData.receive_info.receive_num}次”`);
          this.getDetail();
        });
      }
    },
    taskBt2() {
      if (this.detailData.receive_info.integral_amount <= 0) {
        // eslint-disable-next-line no-undef
        graft.app({
          functionName: "openController",
          functionParams: 'md://user_score'
        });
      }
      if (this.statusType !== 1) {
        return;
      }
      if (this.detailData.receive_info.redeemed_num > 0) {
        post("/apis/activities/prophet/integral_exchange", {
          activity_id: this.activity_id
        }).then(({
          message,
          status
        }) => {
          if (status) {
            return mdToast(message);
          }
          mdToast('兑换成功，预言次数+1次”');
          this.getDetail();
        });
      }
    },
    taskBt3() {
      const opt = {
        item_id: this.detailData.pro_info.id,
        item_name: this.detailData.pro_info.name,
        guessing_id: this.activity_id,
        page_source: "预言家助力",
        share_platform: "wx"
      };
      // eslint-disable-next-line no-undef
      this.sensors.track("ShareMethod", opt, () => {});
      if (this.statusType !== 1) {
        return;
      }
      if (this.detailData.receive_info.assisted_num > 0) {
        const url = `pages/index/index?nextUrl=pages/prophet/assist/assist&to_user_id=${this.user_id}&activity_id=${this.activity_id}&auto_share=1`;
        const share_option = {
          wx_des: `我正在竞猜众筹金额，帮我助个力吧!`,
          wx_title: '我正在竞猜众筹金额，帮我助个力吧!',
          msg_des: '我正在竞猜众筹金额，帮我助个力吧!',
          weixin_share_url: url,
          wxTimeline_share_url: `${url}`,
          img: this.detailData.prize_pic,
          mini_programs_title: "我正在竞猜众筹金额，帮我助个力吧!",
          mina_share_img: this.detailData.prize_pic,
          mina_app_name: "gh_7210ac3f1f08",
          mina_share_path: url
        };
        // eslint-disable-next-line no-undef
        graft.app({
          functionName: "shareToWeChat",
          functionParams: share_option,
          callBack: () => {},
          webFun: () => {
            // eslint-disable-next-line no-undef
            userHeader.sharediv.style.display = 'block';
          }
        });
      }
    },
    async getScheme() {
      // const query = this.$route.query || {}
      // for (let key in query) {
      //     str += `&${key}=${query[key]}`
      // }
      // let str = window.location.search ? window.location.search.substr(1) : "";
      const param = {
        path: "/pages/index/index",
        query: `nextUrl=pages/webviewPages/content/conten&url=${location.href}`
      };
      const option = {
        jump_wxa: JSON.stringify(param)
      };
      const url = await getSchemeUrl(option);
      if (!url) return false;
      sessionStorage.setItem(this.pro_id, JSON.stringify({
        scheme_url: url,
        initial_time: Date.now()
      }));
      return url;
    },
    goToRule() {
      // const opt = {
      //     page_source: "有奖竞猜页",
      //     element_content: "活动规则",
      //     item_id: this.detailData.pro_id,
      //     item_name: this.detailData.pro_title,
      //     guessing_id: this.activity_id,
      // }
      // // eslint-disable-next-line no-undef
      // this.sensors.track("CommonClick", opt, () => { })
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: `${domain.wap}/lottery/rule`,
        webFun: () => {
          this.$router.push('/lottery/rule');
          // location.href = `${domain.wap}/fastsell/lottery/rule`
        }
      });
    },
    goToAll() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: `${domain.wap}/lottery/list`,
        webFun: () => {
          this.$router.push('/lottery/list');
          // location.href = `${domain.wap}/fastsell/lottery/list`
        }
      });
    },
    goToProject(id) {
      MDRouter.goProInfo(id);
    },
    close() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "closeController",
        functionParams: ""
      });
    },
    goToUser() {
      // eslint-disable-next-line no-empty
      if (sessionStorage.getItem("miniProgram") == "weixin") {} else {
        // eslint-disable-next-line no-undef
        graft.app({
          functionName: "openController",
          functionParams: "md://user_score"
        });
      }
    },
    async getDetail() {
      const {
        data
      } = await get("/apis/activities/prophet/detail", {
        activity_id: this.activity_id
      });
      this.statusType = data.status;

      // eslint-disable-next-line no-useless-escape
      this.time = (new Date(data.start_time.replace(/\-/g, "/")).getTime() - new Date().getTime()) / 1000;
      if (this.statusType == 0) {
        this.updateTime();
      }
      this.detailData = data;
      const url = `pages/index/index?nextUrl=pages/prophet/assist/assist&to_user_id=${this.user_id}&activity_id=${this.activity_id}&auto_share=1`;
      if (env.isWxApp()) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.postMessage({
          data: {
            title: '我正在竞猜众筹金额，帮我助个力吧!',
            imageUrl: this.detailData.prize_pic,
            url: `${url}`
          }
        });
      }
      if (data.prophet_record_list.length && this.out_list.length < data.prophet_record_list.length) {
        clearInterval(this.timeOutList);
        this.timeOutList = setInterval(() => {
          if (this.timeOutListIdx < data.prophet_record_list.length) {
            this.out_list.push(data.prophet_record_list[this.timeOutListIdx]);
          }
          this.timeOutListIdx++;
          if (this.timeOutListIdx > data.prophet_record_list.length) {
            this.out_list = [];
            this.timeOutListIdx = 0;
          }
        }, 1500);
      }
      if (data.max_amount) {
        if (data.max_amount > data.pro_info.backer_money) {
          this.greenNum = Number(data.pro_info.backer_money) / Number(data.max_amount) * 100;
        } else {
          this.greenNum = 90;
        }
      } else {
        this.greenNum = 90;
      }
      if (this.statusType == 2) {
        let arr = 0;
        data.self_prophet_record_list.map(e => {
          if (e.amount > data.pro_info.backer_money) {
            if (e.amount < arr || arr === 0) {
              arr = e.amount;
            }
          }
        });
        if (arr !== 0) {
          this.my_quiz_info.push({
            left: Number(arr) / Number(data.max_amount) * 100,
            width: 1
          });
        }
      }
      if (data.status === 3 && data.prize_list) {
        data.prize_list.forEach(e => {
          if (e.self) {
            this.winbg = true;
          }
        });
      }
      const opt = {
        page_source: "有奖竞猜页",
        item_id: data.pro_info.id,
        item_name: data.pro_info.name,
        guessing_id: this.activity_id
      };
      // eslint-disable-next-line no-undef
      this.sensors.track("GuessingPageView", opt, () => {});
      // this.getRecord()
    },
    listScroll() {
      if (this.total > this.allList.length) {
        let scrollHeight = this.$refs.listScroll.scrollHeight;
        let clientHeight = this.$refs.listScroll.clientHeight;
        let scrollTop = this.$refs.listScroll.scrollTop;
        if (scrollHeight - (scrollTop + clientHeight) <= 1) {
          this.timeout = null;
          if (this.timeout !== null) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            this.page++;
            this.getRecord();
          }, 200);
        }
      }
    },
    getRecord() {
      get("/apis/activities/prophet/record", {
        activity_id: this.activity_id,
        page: this.page,
        page_size: 20
      }).then(({
        data
      }) => {
        this.allShow = true;
        this.allList.push(...data.list);
        this.total = data.total;
      });
    },
    bet_info() {
      this.page = 1;
      this.allList = [];
      this.getRecord();
    },
    quizBet() {
      post("/apis/activities/prophet/prediction", {
        activity_id: this.activity_id,
        amount: this.start_money
      }).then(({
        status,
        message
      }) => {
        if (status) {
          return mdToast(message);
        }
        mdToast({
          message: '预言成功，我的预言记录可查看结果',
          type: 'html'
        });
        this.getDetail();
      });
    },
    betOneFn() {
      if (this.detailData.available_times === 0) {
        mdToast(`可用预言次数不足`);
        return;
      }
      if (this.start_money) {
        // const opt = {
        //     page_source: "有奖竞猜页",
        //     element_content: "押注",
        //     item_id: this.detailData.pro_info.id,
        //     item_name: this.detailData.pro_info.name,
        //     guessing_id: this.activity_id,
        // }
        // // eslint-disable-next-line no-undef
        // this.sensors.track("CommonClick", opt, () => { })
        this.quizBet();
      }
    },
    getRandom() {
      post("/apis/activities/prophet/random", {
        activity_id: this.activity_id
      }).then(({
        data,
        status,
        message
      }) => {
        if (status) {
          mdToast(message);
        } else {
          this.start_money = data;
        }
      });
    },
    cdInit(time) {
      this.time -= 1;
      let cd_time = time;
      this.day = Math.floor(cd_time / (24 * 60 * 60));
      this.hour = Math.floor(cd_time % (24 * 60 * 60) / (60 * 60));
      this.minute = Math.floor(cd_time % (24 * 60 * 60) % (60 * 60) / 60);
      this.sec = Math.floor(cd_time % (24 * 60 * 60) % (60 * 60) % 60);
      this.hour = this.hour > 9 ? this.hour : "0" + this.hour;
      this.minute = this.minute > 9 ? this.minute : "0" + this.minute;
      this.sec = this.sec > 9 ? this.sec : "0" + this.sec;
    },
    updateTime() {
      let that = this;
      setInterval(function () {
        that.cdInit(that.time);
        if (that.time < 0) {
          location.reload();
        }
      }, 1000);
    }
  }
};