import { getProphetProList } from "@/api";
import { numFitWanFormat } from "@/utils/tools";
import { getSchemeUrl } from "@/utils/toolsApi";
export default {
  props: {
    pro_id: String,
    activity_id: String
  },
  data() {
    return {
      list: [],
      statusMap: {
        1: '进行中',
        2: '待开奖',
        3: '已开奖'
      }
    };
  },
  mounted() {
    if (this.pro_id) {
      getProphetProList({
        pro_id: this.pro_id
      }).then(res => {
        if (res.status == 0) {
          this.list = res.data;
        }
      });
    }
  },
  methods: {
    async predictionAction(item) {
      if (item.activity_id != this.activity_id) {
        const url = await this.getScheme(item.activity_id);
        if (url) {
          location.href = url;
        }
      }
    },
    numFitWanFormat(num) {
      return numFitWanFormat(num);
    },
    async getScheme(activity_id) {
      const param = {
        path: "/pages/index/index",
        query: `nextUrl=pages/prophet/detail/detail&activity_id=${activity_id}`
      };
      const option = {
        jump_wxa: JSON.stringify(param)
      };
      const url = await getSchemeUrl(option);
      if (!url) return false;
      return url;
    }
  }
};